import Cookies from "js-cookie";

document.addEventListener("DOMContentLoaded", () => {
  const COOKIE_KEY = "hide_cookie_notice";

  if (Cookies.get(COOKIE_KEY) || !document.getElementById("js-cookie-balloon")) {
    return;
  }

  const content = document.getElementById("js-cookie-balloon");
  const acceptButton = document.getElementById("js-cookie-accept");

  content.classList.add("is-show");

  const hide = () => {
    content.classList.remove("is-show");
  };
  acceptButton.addEventListener("click", (event) => {
    // NOTE: 最大400日保持することができ、それ以上設定してもブラウザ側で自動的に変更
    //       https://developer.chrome.com/blog/new-in-chrome-104/#more
    Cookies.set(COOKIE_KEY, "1", { expires: 400, secure: true });
    content.remove();
    event.preventDefault();
  });

  return { hide: hide };
});
